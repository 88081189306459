import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Downshift from "downshift"
import InputText from "@src/components/input-text"
import Icon from "@src/components/core-icon"
const DropdownWrapper = styled.div`
  position: relative;
`
const DropdownSelectArrow = css`
  position: absolute;
  display: block;
  content: "";
  width: 0;
  height: 0;

  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid ${props => props.theme.lightGray};
`
const DropdownComboboxInput = styled(InputText)`
  padding-right: ${props =>
    props.$hasOptions && props.$hasClearButton
      ? "50px"
      : props.$hasOptions
      ? "26px"
      : props.$hasClearButton
      ? "30px"
      : "10px"};
  @media only screen and (${props => props.theme.screen.small.min}) {
    padding-right: ${props =>
      props.$hasOptions && props.$hasClearButton
        ? "60px"
        : props.$hasOptions
        ? "30px"
        : props.$hasClearButton
        ? "40px"
        : "12px"};
  }
`
const DropdownComboboxArrow = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 26px;
  &::after {
    ${DropdownSelectArrow}
    right: 10px;
    bottom: 12px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 30px;
    &::after {
      bottom: 16px;
    }
  }
`
const DropdownSelectButton = styled.button`
  cursor: pointer;
  background-color: ${props => props.theme.bg1};
  color: ${props => props.theme.lightGray};
  border: 1px solid ${props => props.theme.darkGray};
  border-radius: 4px;

  padding: 0 26px 0 6px;
  font-size: 16px;
  line-height: 15px;
  height: 34px;
  width: 100%;
  text-align: left;

  &.has-value {
    color: ${props => props.theme.darkGray};
  }

  &:disabled {
    cursor: not-allowed;
  }

  position: relative;
  &::after {
    ${DropdownSelectArrow}
    right: 10px;
    bottom: 12px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    padding: 0 30px 0 10px;
    font-size: 16px;
    line-height: 20px;
    height: 42px;
    font-weight: 500;

    &::after {
      bottom: 16px;
    }
  }
`
const DropdownStyledOptions = styled.ul`
  list-style-type: none;

  position: absolute;
  z-index: ${props => props.theme.layers.activeUI};
  min-width: 100%;
  max-height: 250px;
  overflow-y: auto;

  background-color: ${props => props.theme.bg1};
  border-radius: 8px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);

  cursor: default;

  ${props =>
    props.isOpen &&
    css`
      padding: 10px 0;
    `}
`
const DropdownStyledOption = styled.li`
  ${props =>
    props.highlight || props.selected
      ? css`
          background-color: ${props => props.theme.highlight};
        `
      : undefined}

  cursor: pointer;

  padding: 12px 10px 10px;
  font-size: 16px;
  line-height: 18px;
  &.empty-value {
    color: ${props => props.theme.darkGray};
    font-weight: bold;
    font-style: italic;
  }
`
const ClearButton = styled.button`
  background-color: transparent;
  color: ${props => props.theme.lightGray};
  padding: 0;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${props => (props.$hasOptions ? "26px" : "0")};
  padding: 6px ${props => (props.$hasOptions ? "2px" : "6px")} 7px;
  & > .icon {
    font-size: 18px;
  }
  &:hover {
    color: ${props => props.theme.darkGray};
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    right: ${props => (props.$hasOptions ? "30px" : "0")};
    padding: 9px ${props => (props.$hasOptions ? "2px" : "8px")};
    & > .icon {
      font-size: 24px;
    }
  }
`

const dropdownItemToString = item => {
  if (!item) {
    return ""
  } else if (typeof item === "object" && !(item instanceof String)) {
    return `${item.text || item.name || item.value || ""}`
  } else if (item) {
    return `${item}`
  }
  return ""
}
export const filterDropdownText = (text, filterValue) =>
  !filterValue || text.toLowerCase().startsWith(filterValue.toLowerCase())
export const filterDropdownOption = (item, filterValue) =>
  !item.value ||
  !filterValue ||
  (item.text && filterDropdownText(item.text, filterValue))

const InputDropdown = ({
  id,
  options: items,
  placeholder,
  clearButton,
  value,
  filterSelection,
  onChange,
  onBlur,
  onFocus,
  combobox,
  getFilteredOptions: getFilteredItems,
  renderSelectButton,
  className,
}) => {
  const [inputValue, setInputValue] = useState(dropdownItemToString(value))
  const [selectItems, setSelectItems] = useState(items)

  const hasOptions = (selectItems && selectItems.length) || false

  const isSelected = item =>
    value && item && (item === value || item.value === value)

  useEffect(() => {
    if (combobox && inputValue) {
      if (getFilteredItems) {
        setSelectItems(getFilteredItems(inputValue))
      } else {
        setSelectItems(
          items.filter(item => filterDropdownOption(item, inputValue))
        )
      }
    } else {
      setSelectItems(items)
    }
  }, [combobox, items, getFilteredItems, inputValue])
  useEffect(() => {
    setInputValue(dropdownItemToString(value))
  }, [value])

  const handleChange = selection => {
    if (filterSelection) {
      selection = filterSelection(selection)
    }
    if (onChange) {
      onChange(selection)
    }
    setInputValue(dropdownItemToString(selection))
  }
  const handleStateChange = change => {
    if (change.isOpen === false && onBlur) {
      onBlur()
    } else if (change.isOpen === true && onFocus) {
      onFocus()
    }
  }
  const handleInputChange = event => {
    if (event && event.target) {
      setInputValue(event.target.value)
    }
  }
  const handleClearClick = () => {
    handleChange(null)
  }
  return (
    <Downshift
      id={id}
      onChange={handleChange}
      onStateChange={handleStateChange}
      itemToString={dropdownItemToString}
      inputValue={inputValue}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        isOpen,
        highlightedIndex,
        getRootProps,
        openMenu,
        closeMenu,
        toggleMenu,
      }) => {
        return (
          <DropdownWrapper className={className}>
            <div {...getRootProps({}, { suppressRefError: true })}>
              {combobox ? (
                <>
                  <DropdownComboboxInput
                    {...getInputProps()}
                    onKeyDown={() => {}}
                    onChange={handleInputChange}
                    onFocus={() => {
                      openMenu()
                    }}
                    onBlur={() => {
                      closeMenu()
                    }}
                    placeholder={placeholder || "Select"}
                    value={inputValue}
                    $hasClearButton={clearButton && inputValue}
                    $hasOptions={hasOptions}
                  />
                  {hasOptions ? (
                    <DropdownComboboxArrow
                      type="button"
                      {...getToggleButtonProps()}
                      onClick={() => {
                        toggleMenu()
                      }}
                    />
                  ) : null}
                </>
              ) : (
                <DropdownSelectButton
                  type="button"
                  disabled={!hasOptions}
                  {...getToggleButtonProps()}
                  className={`select-button${inputValue ? " has-value" : ""}`}
                  aria-label="toggle menu"
                >
                  {renderSelectButton
                    ? renderSelectButton(value)
                    : inputValue || placeholder || "Select"}
                </DropdownSelectButton>
              )}
              {clearButton && inputValue && (
                <ClearButton
                  onClick={handleClearClick}
                  $hasOptions={hasOptions}
                  aria-label="Reset selection"
                >
                  <Icon glyph="close" />
                </ClearButton>
              )}
            </div>
            {hasOptions ? (
              <DropdownStyledOptions isOpen={isOpen} {...getMenuProps()}>
                {isOpen &&
                  selectItems.map((item, index) => (
                    <DropdownStyledOption
                      highlight={highlightedIndex === index}
                      selected={isSelected(item)}
                      key={`${dropdownItemToString(item)}${index}`}
                      {...getItemProps({ item, index })}
                      className={
                        (item.className ? item.className : "") +
                        (!item.value ? " empty-value" : "")
                      }
                    >
                      {item.icon}
                      {dropdownItemToString(item)}
                    </DropdownStyledOption>
                  ))}
              </DropdownStyledOptions>
            ) : null}
          </DropdownWrapper>
        )
      }}
    </Downshift>
  )
}
InputDropdown.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      // other properties may be added to options to pass details to onChange
    })
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ]),
  placeholder: PropTypes.string,
  clearButton: PropTypes.bool,
  filterSelection: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  combobox: PropTypes.bool,
  getFilteredOptions: PropTypes.func,
  renderSelectButton: PropTypes.func,
  className: PropTypes.string,
}
export default InputDropdown
